.login-page {
  /* background: rgb(41,50,162);
  background: linear-gradient(131deg, rgba(41,50,162,1) 0%, rgba(9,39,121,1) 46%, rgba(0,212,255,1) 98%); */
  background: linear-gradient(126deg, #001eff, #009aff, #00ffd2);
  background-size: 180% 180%;
  animation: gradient-animation 15s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.input-error-desc {
  color: red;
}
.input-error-desc:first-letter {
  text-transform: capitalize;
}
.table-input {
  border-radius: 5px;
  border-width: 1px !important;
  padding: 5px;
  margin: 5px auto;
  width: 75% !important;
}
.mark-table tr,
th {
  text-align: center !important;
  vertical-align: middle;
}
.mark-table input {
  text-align: center;
  text-transform: capitalize;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-Primary.MuiButton-sizeMedium.MuiButton-SizeMedium.MuiButton-root.MuiButton-Primary.MuiButton-sizeMedium.MuiButton-SizeMedium.tss-1fbujeu-MUIDataTableHeadCell-toolButton.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  margin: auto;
  font-weight: bold;
}
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  text-align: center;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  margin: auto !important;
}
.uppercase {
  text-transform: uppercase;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.capitalize {
  text-transform: capitalize;
}
.application-view .heading-content {
  line-height: 0.25;
  text-align: center;
  font-size: 12px;
}
.application-view .application-details p {
  font-size: 14px;
  width: 100%;
}
.application-view .application-details table,
.application-view .application-details th,
.application-view .application-details tr {
  border-collapse: collapse;
  border: 1px solid grey;
}
.application-view .application-details td {
  padding: 8px 30px;
}

.application-view .application-details td .value {
  /* width: 50%; */
  font-weight: bold;
  padding-left: 10px;
  text-transform: capitalize;
  /* white-space: nowrap; */
}
.application-view .application-details td .label {
  /* font-weight: bold; */
  font-size: 13px;
  text-transform: capitalize;
}
.application-view .application-details td span {
  font-size: 13px;
}
.application-view .form-table td {
  width: 50%;
}
#mark-table-preview {
  font-size: 13px !important;
}
#mark-table-preview {
  font-size: 13px !important;
}

#mark-table-preview .custom--mark-width {
  width: 10%;
}
#mark-table-preview .custom-mark-width {
  width: 10%;
}
#mark-table-preview td {
  text-align: center;
}

.application-view {
  font-family: sans-serif;
  /* border: 1px solid black; */
}
.custom--subject-width {
  width: 40%;
}
@media print {
  .page-break {
    page-break-before: always;
    margin-top: 25px;
  }
}
